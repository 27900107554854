// Main JS file
TweenLite.from('.g-line', 1, {
	drawSVG: 0,
	stagger: 0.05,
	duration: 1,
	ease: 'power1.inOut',
});

ScrollTrigger.matchMedia({
	all: function () {
		TweenLite.from('.focus-text', {
			delay: 0.5,
			opacity: 0,
		});
	},
	'(min-width: 768px': function () {
		gsap.from('.focus-item-left', {
			scrollTrigger: {
				trigger: '.focus',
				start: 'bottom bottom',
			},
			x: '200%',
			ease: 'power1.inOut',
			duration: 0.8,
		});
		gsap.from('.focus-item-right', {
			scrollTrigger: {
				trigger: '.focus',
				start: 'bottom bottom',
			},
			ease: 'power1.inOut',
			duration: 0.8,
			x: '-200%',
		});
	},

	'(max-width: 768px': function () {
		gsap.from('.focus-item-left', {
			scrollTrigger: {
				trigger: '.focus',
				start: 'bottom bottom',
			},
			ease: 'power1.inOut',
			duration: 0.8,
			x: '22vw',
			y: -120,
		});
		gsap.from('.focus-item-right', {
			scrollTrigger: {
				trigger: '.focus',
				start: 'bottom bottom',
			},
			ease: 'power1.inOut',
			duration: 0.8,
			x: '-22vw',
			y: -120,
		});
	},
});
// gsap.from('.focus-line', 4, {
// 	scrollTrigger: {
// 		trigger: '.focus',
// 		start: 'bottom bottom',
// 	},
// 	opacity: 0,
// });
TweenLite.from('.line-left', 1, {
	drawSVG: '100% 100%',
	ease: 'power1.inOut',
	scrollTrigger: {
		trigger: '.focus',
		start: 'bottom bottom',
	},
});

TweenLite.from('.line-right', 1, {
	drawSVG: 0,
	ease: 'power1.inOut',
	scrollTrigger: {
		trigger: '.focus',
		start: 'bottom bottom',
	},
});
