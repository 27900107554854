// About JS file
TweenLite.from('.about-svg', 1, {
	drawSVG: '100% 100%',
	stagger: 0.06,
	duration: 1,
	ease: 'power1.inOut',
});

TweenLite.from('.buildings1', 1.8, {
	drawSVG: 0,
	ease: 'slow(0.5, 0.5, false)',
	scrollTrigger: {
		trigger: '.buildings1-container',
		start: 'top center',
	},
});
TweenLite.from('.buildings1-particle', 1, {
	drawSVG: 0,
	stagger: 0.15,
	scrollTrigger: {
		trigger: '.buildings1-container',
		start: 'top center',
	},
});

TweenLite.from('.tree', 1.8, {
	drawSVG: '50% 50%',
	ease: 'slow(0.5, 0.5, false)',
	scrollTrigger: {
		trigger: '.tree-container',
		start: 'top center',
	},
});
TweenLite.from('.tree-particle', 1, {
	drawSVG: 0,
	stagger: 0.1,
	scrollTrigger: {
		trigger: '.tree-container',
		start: 'top center',
	},
});

TweenLite.from('.buildings2', 1.8, {
	drawSVG: '100% 100%',
	ease: 'slow(0.5, 0.5, false)',
	scrollTrigger: {
		trigger: '.buildings2-container',
		start: 'top center',
	},
});
TweenLite.from('.buildings2-particle', 1, {
	drawSVG: 0,
	stagger: 0.18,
	scrollTrigger: {
		trigger: '.buildings2-container',
		start: 'top center',
	},
});
